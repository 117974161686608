<template>
  <NeoPageLayout>
    <div class="companyInfo">
      <!-- 标题 -->
      <h2 class="title">{{ baseInfo.name }}</h2>
      <!-- Tab切换 -->
      <div class="radio">
        <a-radio-group v-model="tab">
          <a-radio-button value="baseInfo">{{ $t('coConfig.base_info') }}</a-radio-button>
          <a-radio-button value="supplier">{{ $t('coConfig.supplier_management') }}</a-radio-button>
          <a-radio-button value="manager">{{ $t('coConfig.manager') }}</a-radio-button>
        </a-radio-group>
      </div>

      <!-- 基本信息 -->
      <div class="baseInfoBox" v-show="tab1">
        <a-spin :spinning="loading1">
          <!-- 基本信息描述列表 -->
          <div class="baseInfo">
            <div class="title-btn-container">
              <NeoTitle :text="$t('companyInfo.base_info')" />
              <template>
                <a-button class="onceEditButton" type="primary btn-update" size="small" @click="handelBaseInfoEdit()">{{
                  baseInfoEdit ? $t('save') : $t('edit')
                }}</a-button>
              </template>
            </div>
            <a-descriptions bordered size="small" :column="2" class="infoBox">
              <a-descriptions-item :label="$t('coConfig.coTax')">
                <span>{{ baseInfo.taxIdNumber }}</span>
              </a-descriptions-item>
              <a-descriptions-item :label="$t('coConfig.company_name')">
                <span>{{ baseInfo.name }}</span>
              </a-descriptions-item>
              <a-descriptions-item :label="$t('coConfig.currency')">
                <span>{{ getCurrency(baseInfo.currency) }}</span>
              </a-descriptions-item>
              <a-descriptions-item :label="$t('coConfig.company_address')">
                <span v-show="!baseInfoEdit">{{ baseInfo.address }}</span>
                <a-input v-show="baseInfoEdit" v-model="baseInfo.address" :maxLength="50"></a-input>
              </a-descriptions-item>
            </a-descriptions>
          </div>

          <!-- 行业领域&产品线 -->
          <div class="field">
            <div class="title-btn-container">
              <div>
                <NeoTitle :text="$t('coConfig.field')" />
                <a-tooltip placement="top" :title="$t('coConfig.fieldTip')">
                  <a-icon type="question-circle" />
                </a-tooltip>
              </div>
              <a-button type="primary" size="small" @click="showProductLineModal">{{
                $t('coConfig.addNewField')
              }}</a-button>
            </div>
            <!-- 新增行业领域&产品线弹窗 -->
            <a-modal
              width="700px"
              v-model="addProductLineVisible"
              :title="$t('coConfig.addProductLine')"
              :footer="null"
              centered
              @cancel="handleProductLineCancel"
            >
              <a-form-model
                :colon="false"
                ref="productLineForm"
                :rules="productLineFormDataRules"
                v-bind="layout.form"
                :model="productLineFormData"
              >
                <div class="addStepOne">
                  <a-row>
                    <a-col :span="24">
                      <a-form-model-item label="产品线名" prop="name">
                        <a-input
                          v-model="productLineFormData.name"
                          :placeholder="$t('customerConfig.productLineName_placeholder')"
                          :maxLength="50"
                        ></a-input>
                      </a-form-model-item>
                    </a-col>
                    <a-col :span="24">
                      <a-form-model-item label="行业&领域" prop="industry">
                        <a-input
                          v-model="productLineFormData.industry"
                          :placeholder="$t('customerConfig.productLineIndustry_placeholder')"
                          :maxLength="10"
                        ></a-input>
                      </a-form-model-item>
                    </a-col>
                    <a-col :span="24">
                      <a-form-model-item label="补充说明" prop="remark">
                        <a-input
                          class="textarea"
                          v-model="productLineFormData.remark"
                          type="textarea"
                          :maxLength="100"
                        />
                      </a-form-model-item>
                    </a-col>
                  </a-row>
                </div>
              </a-form-model>
              <template>
                <a-button key="submit" type="primary" :loading="loading" @click="handleProductLineOk">
                  {{ $t('submit') }}
                </a-button>
              </template>
            </a-modal>
            <!-- 行业领域&产品线表格 -->
            <a-table
              rowKey="id"
              :columns="productLineColumns"
              :data-source="productLine"
              bordered
              :pagination="false"
              class="head-bg no-br"
            >
              <template v-for="col in productLineCols" :slot="col.key" slot-scope="text, record">
                <div :key="col.key">
                  <a-input
                    v-if="record.editable"
                    style="margin: -5px 0"
                    :value="text"
                    :maxLength="col.maxLength"
                    @change="(e) => handleChangeProductLine(e.target.value, record.id, col.key)"
                  />
                  <template v-else>
                    {{ text }}
                  </template>
                </div>
              </template>
              <template slot="operation" slot-scope="text, record, index">
                <div class="editable-row-operations">
                  <span v-if="record.editable">
                    <a @click="() => handleSaveProductLine(record.id, index)">{{ $t('save') }}</a>
                    <a @click="() => handleCancelProductLine(record.id)">{{ $t('cancel') }}</a>
                  </span>
                  <span v-else>
                    <a :disabled="productLineEditingKey !== ''" @click="() => handleEditProductLine(record.id)">{{
                      $t('edit')
                    }}</a>
                  </span>
                  <a-popconfirm
                    v-if="productLine.length && !record.editable && !record.usedFlag"
                    :title="$t('customerConfig.delete_confirm_title')"
                    @confirm="() => handleDeleteProductLine(record.id)"
                  >
                    <a :disabled="productLineEditingKey !== ''" href="javascript:;">{{ $t('delete') }}</a>
                  </a-popconfirm>
                </div>
              </template>
            </a-table>
          </div>

          <!-- 服务类型 -->
          <div class="field-service">
            <div class="title-btn-container">
              <NeoTitle :text="$t('coConfig.serviceType')" />
              <a-button type="primary" size="small" @click="showServiceTypeModal">{{
                $t('coConfig.addNewServiceType')
              }}</a-button>
            </div>
            <!-- 新增服务类型弹窗 -->
            <a-modal
              width="700px"
              v-model="addServiceTypeVisible"
              :title="$t('coConfig.addNewServiceType')"
              :footer="null"
              centered
              @cancel="handleServiceTypeCancel"
            >
              <a-form-model
                :colon="false"
                ref="serviceTypeForm"
                :rules="serviceTypeFormDataRules"
                v-bind="layout.form"
                :model="serviceTypeFormData"
              >
                <div class="addStepOne">
                  <a-row>
                    <a-col :span="24">
                      <a-form-model-item :label="$t('coConfig.serviceType')" prop="label">
                        <a-input v-model="serviceTypeFormData.label" :maxLength="10"></a-input>
                      </a-form-model-item>
                    </a-col>
                  </a-row>
                </div>
              </a-form-model>
              <template>
                <a-button key="submit" type="primary" :loading="loading" @click="handleServiceTypeOk()">
                  {{ $t('submit') }}
                </a-button>
              </template>
            </a-modal>
            <!-- 服务类型表格 -->
            <a-table
              rowKey="id"
              :columns="serviceTypeColumns"
              :data-source="serviceType"
              bordered
              :showHeader="false"
              :pagination="false"
            >
              <template v-for="col in [{ key: 'label', maxLength: 10 }]" :slot="col.key" slot-scope="text, record">
                <div :key="col.key">
                  <a-input
                    v-if="record.editable"
                    style="margin: -5px 0"
                    :value="text"
                    :maxLength="col.maxLength"
                    @change="(e) => handleChangeServiceType(e.target.value, record.id, col.key)"
                  />
                  <template v-else>
                    {{ text }}
                  </template>
                </div>
              </template>
              <template slot="operation" slot-scope="text, record, index">
                <div class="editable-row-operations" v-if="record.value == null">
                  <span v-if="record.editable">
                    <a @click="() => handleSaveServiceType(record.id, index)">{{ $t('save') }}</a>
                    <a @click="() => handleCancelServiceType(record.id)">{{ $t('cancel') }}</a>
                  </span>
                  <span v-else>
                    <a :disabled="serviceTypeEditingKey !== ''" @click="() => handleEditServiceType(record.id)">{{
                      $t('edit')
                    }}</a>
                  </span>
                  <a-popconfirm
                    v-if="serviceType.length && !record.editable && !record.usedFlag"
                    :title="$t('customerConfig.delete_confirm_title')"
                    @confirm="() => handleDeleteServiceType(record.id)"
                  >
                    <a :disabled="serviceTypeEditingKey !== ''" href="javascript:;">{{ $t('delete') }}</a>
                  </a-popconfirm>
                </div>
              </template>
            </a-table>
          </div>

          <!-- 文档类型 -->
          <div class="field-document">
            <div class="title-btn-container">
              <NeoTitle :text="$t('coConfig.documentType')" />
              <a-button type="primary" size="small" @click="showDocumentTypeModal">{{
                $t('coConfig.addNewDocumentType')
              }}</a-button>
            </div>
            <!-- 新增文档类型弹窗 -->
            <a-modal
              width="700px"
              v-model="addDocumentTypeVisible"
              :title="$t('coConfig.addNewDocumentType')"
              :footer="null"
              centered
              @cancel="handleDocumentTypeCancel"
            >
              <a-form-model
                :colon="false"
                ref="documentTypeForm"
                :rules="documentTypeFormDataRules"
                v-bind="layout.form"
                :model="documentTypeFormData"
              >
                <div class="addStepOne">
                  <a-row>
                    <a-col :span="24">
                      <a-form-model-item :label="$t('coConfig.documentType')" prop="label">
                        <a-input v-model="documentTypeFormData.label" :maxLength="10"></a-input>
                      </a-form-model-item>
                    </a-col>
                  </a-row>
                </div>
              </a-form-model>
              <template>
                <a-button key="submit" type="primary" :loading="loading" @click="handleDocumentTypeOk()">
                  {{ $t('submit') }}
                </a-button>
              </template>
            </a-modal>
            <!-- 文档类型表格 -->
            <a-table
              rowKey="id"
              :columns="documentTypeColumns"
              :data-source="documentType"
              bordered
              :showHeader="false"
              :pagination="false"
            >
              <template v-for="col in [{ key: 'label', maxLength: 10 }]" :slot="col.key" slot-scope="text, record">
                <div :key="col.key">
                  <a-input
                    v-if="record.editable"
                    style="margin: -5px 0"
                    :value="text"
                    :maxLength="col.maxLength"
                    @change="(e) => handleChangeDocumentType(e.target.value, record.id, col.key)"
                  />
                  <template v-else>
                    {{ text }}
                  </template>
                </div>
              </template>
              <template slot="operation" slot-scope="text, record, index">
                <div class="editable-row-operations" v-if="record.value == null">
                  <span v-if="record.editable">
                    <a @click="() => handleSaveDocumentType(record.id, index)">{{ $t('save') }}</a>
                    <a @click="() => handleCancelDocumentType(record.id)">{{ $t('cancel') }}</a>
                  </span>
                  <span v-else>
                    <a :disabled="documentTypeEditingKey !== ''" @click="() => handleEditDocumentType(record.id)">{{
                      $t('edit')
                    }}</a>
                  </span>
                  <a-popconfirm
                    v-if="documentType.length && !record.editable && !record.usedFlag"
                    :title="$t('customerConfig.delete_confirm_title')"
                    @confirm="() => handleDeleteDocumentType(record.id)"
                  >
                    <a :disabled="documentTypeEditingKey !== ''" href="javascript:;">{{ $t('delete') }}</a>
                  </a-popconfirm>
                </div>
              </template>
            </a-table>
          </div>
        </a-spin>
      </div>

      <!-- 供应商管理 -->
      <SupplierManage :show="tab2" :cusEntityId="baseInfo.id" :agencyInfo="agencyInfo" />

      <!-- 下单经理 -->
      <OrderManager :show="tab3" :agencyInfo="agencyInfo" />
    </div>
  </NeoPageLayout>
</template>

<script>
import withAsync from '@/mixins/withAsync'
import SupplierManage from './components/supplierManage'
import OrderManager from './components/OrderManager'
const { localeDict, validate } = window.$g
export default {
  name: 'coConfig',
  mixins: [withAsync],
  components: { SupplierManage, OrderManager },
  provide() {
    return {
      managerList: this.managerList,
    }
  },
  data() {
    return {
      tab: 'baseInfo',
      tabs: ['baseInfo', 'supplier', 'manager'],
      managerList: [
        {
          value: 1,
          label: this.$t('managerInfo.manager'),
        },
        {
          value: 3,
          label: this.$t('managerInfo.order_admin'),
        },
      ],
      agencyInfo: {
        id: 1405797586270552066,
      },
      loading: false,
      loading1: false,
      localeDict,
      companyName: '',
      editingKey: '',
      baseInfoEdit: false,
      // 公司基本信息
      baseInfo: {},
      // 行业领域
      productLineCacheData: [],
      productLine: [],
      productLineColumns: [
        {
          title: '产品线名',
          dataIndex: 'name',
          width: '20%',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: '行业&领域',
          dataIndex: 'industry',
          width: '30%',
          scopedSlots: { customRender: 'industry' },
        },
        {
          title: '补充内容',
          dataIndex: 'remark',
          width: '32%',
          scopedSlots: { customRender: 'remark' },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      productLineEditingKey: '',
      addProductLineVisible: false,
      productLineCols: [
        { key: 'name', maxLength: 50 },
        { key: 'industry', maxLength: 10 },
        { key: 'remark', maxLength: 100 },
      ],
      // 添加行业领域表单信息
      productLineFormData: {
        name: '',
        industry: '',
        remark: '',
      },
      // 表单验证
      productLineFormDataRules: {
        name: [validate.required],
        industry: [validate.required],
      },

      // 服务类型
      serviceTypeCacheData: [],
      serviceType: [],
      serviceTypeColumns: [
        {
          title: '服务类型',
          width: '20%',
          customRender: (text, row, index) => {
            const obj = {
              children: '服务类型',
              attrs: {},
            }
            if (index === 0) {
              // 第一行数据开始，跨行合并的长度为数据data的长度
              obj.attrs.rowSpan = this.serviceType.length
            }
            if (index >= 1) {
              // 从第一行往后的所有行表格均合并
              obj.attrs.rowSpan = 0
            }
            return obj
          },
        },
        {
          title: 'label',
          dataIndex: 'label',
          width: '50%',
          scopedSlots: { customRender: 'label' },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      serviceTypeEditingKey: '',
      addServiceTypeVisible: false,
      // 添加服务类型表单信息
      serviceTypeFormData: {
        label: '',
      },
      // 表单验证
      serviceTypeFormDataRules: {
        label: [validate.required],
      },

      // 文档类型的数据
      documentTypeCacheData: [],
      documentType: [],
      documentTypeColumns: [
        {
          title: '文档类型',
          dataIndex: 'st',
          width: '20%',
          scopedSlots: { customRender: 'st' },
          customRender: (text, row, index) => {
            const obj = {
              children: '文档类型',
              attrs: {},
            }
            if (index === 0) {
              // 第一行数据开始，跨行合并的长度为数据data的长度
              obj.attrs.rowSpan = this.documentType.length
            }
            if (index >= 1) {
              // 从第一行往后的所有行表格均合并
              obj.attrs.rowSpan = 0
            }
            return obj
          },
        },
        {
          title: 'label',
          dataIndex: 'label',
          width: '50%',
          scopedSlots: { customRender: 'label' },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      documentTypeEditingKey: '',
      addDocumentTypeVisible: false,
      // 添加文档类型表单信息
      documentTypeFormData: {
        label: '',
      },
      // 表单验证
      documentTypeFormDataRules: {
        label: [validate.required],
      },

      validate: window.$g.validate,
      layout: {
        item: {
          labelCol: { flex: '100px' },
          wrapperCol: { flex: 'auto' },
        },
        item_long: {
          labelCol: { flex: '150px' },
          wrapperCol: { flex: 'auto' },
        },
      },
    }
  },
  created() {
    if (this.$route?.query?.tab == 2) {
      this.tab = this.tabs[1]
    }
    this.fetchAll()
  },
  computed: {
    tab1() {
      return this.tab === this.tabs[0]
    },
    tab2() {
      return this.tab === this.tabs[1]
    },
    tab3() {
      return this.tab === this.tabs[2]
    },
    cusAgencyInfo() {
      return { ...this.$store.state.app.cusAgencyInfo }
    },
  },
  watch: {
    tab1(v) {
      v && this.getBaseInfo()
      this.baseInfo = { ...this.$store.state.app.cusAgencyInfo }
    },
    cusAgencyInfo: {
      immediate: true,
      deep: true,
      handler() {
        this.baseInfo = { ...this.$store.state.app.cusAgencyInfo }
      },
    },
  },
  methods: {
    fetchAll() {
      this.baseInfo = { ...this.$store.state.app.cusAgencyInfo }
      this.getBaseInfo()
    },
    getBaseInfo() {
      this.getCusProductLineList()
      this.getCusServiceTypeList()
      this.getCusDocumentTypeList()
    },
    // 处理企业信息编辑
    async handelBaseInfoEdit() {
      if (this.baseInfoEdit) {
        const { address } = this.baseInfo
        if (this.$g.isEmpty(address)) {
          this.$message.error(this.$t('customerConfig.info_empty'))
          return
        }
        const formData = { id: this.baseInfo.id, address: this.baseInfo.address, updateDate: this.baseInfo.updateDate }
        try {
          const data = await this.$http('cusInfoUpdate', formData)
          console.log(data)
          const cusAgencyInfo = await this.$http({ key: 'getCusAgencyInfo', params: { id: this.baseInfo.id } })
          // vuex
          this.$store.commit('app/update', { cusAgencyInfo: cusAgencyInfo })
          // // vuex
          // this.$store.commit('app/update', {
          //   cusAgencyInfo: { ...this.cusAgencyInfo, address: data.address, updateDate: data.updateDate },
          // })
          this.baseInfoEdit = !this.baseInfoEdit
        } catch (err) {
          this.$httpNotify(err)
        }
      } else {
        this.baseInfoEdit = !this.baseInfoEdit
      }
    },

    // 新增产品线-show
    showProductLineModal() {
      this.addProductLineVisible = true
      console.log('showProductLineModal')
    },
    // 新增产品线-cancel
    handleProductLineCancel() {
      this.addProductLineVisible = false
      this.$refs.productLineForm.resetFields()
      console.log('handleProductLineCancel')
    },
    // 新增产品线-submit
    async handleProductLineOk() {
      console.log('handleProductLineOk')
      this.$refs.productLineForm.validate(async (valid) => {
        if (valid) {
          this.loading = true
          try {
            const data = await this.$http('saveCusProductLine', {
              ...this.productLineFormData,
              entityId: this.baseInfo.id,
              cusTenantCode: this.baseInfo.cusTenantCode,
            })
            if (data) {
              setTimeout(() => {
                this.$refs.productLineForm.resetFields()
                this.addProductLineVisible = false
                this.loading = false
                this.getCusProductLineList()
              }, 1000)
            }
          } catch (err) {
            this.loading = false
            this.$httpNotify(err)
          }
        }
      })
    },
    // 编辑产品线表格数据-handleChange
    handleChangeProductLine(value, id, column) {
      const newData = [...this.productLine]
      const target = newData.filter((item) => id === item.id)[0]
      if (target) {
        target[column] = value
        this.productLine = newData
      }
    },
    // 编辑产品线表格数据-handleEdit
    handleEditProductLine(id) {
      const newData = [...this.productLine]
      const target = newData.filter((item) => id === item.id)[0]
      this.productLineEditingKey = id
      if (target) {
        target.editable = true
        this.productLine = newData
      }
    },
    // 编辑产品线表格数据-handleSave
    async handleSaveProductLine(id, index) {
      const newData = [...this.productLine]
      const newCacheData = [...this.productLineCacheData]
      console.log(newCacheData, index, id)
      const target = newData.filter((item) => id === item.id)[0]
      const targetCache = newCacheData.filter((item) => id === item.id)[0]
      try {
        const data = await this.$http('updateCusProductLine', {
          ...target,
          entityId: this.baseInfo.id,
          cusTenantCode: this.baseInfo.cusTenantCode,
        })
        if (data) {
          if (target && targetCache) {
            delete target.editable
            this.productLine = newData
            console.log('target: ' + target)
            console.log('targetCache: ' + targetCache)
            Object.assign(targetCache, target)
            this.productLineCacheData = newCacheData
          }
          this.productLineEditingKey = ''
        }
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 编辑产品线表格数据-handleCancel
    handleCancelProductLine(id) {
      const newData = [...this.productLine]
      const target = newData.filter((item) => id === item.id)[0]
      this.productLineEditingKey = ''
      if (target) {
        Object.assign(target, this.productLineCacheData.filter((item) => id === item.id)[0])
        delete target.editable
        this.productLine = newData
      }
    },
    // 编辑产品线表格数据-handleDelete
    async handleDeleteProductLine(id) {
      try {
        const data = await this.$http('deleteCusProductLine', [id])
        if (data) {
          const dataSource = [...this.productLine]
          this.productLine = dataSource.filter((item) => item.id !== id)
        }
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 客户产品线表-列表
    async getCusProductLineList() {
      const agencyId = this.$store.state.app.userInfo.agencyId
      try {
        const data = await this.$http('cusProductLineList', { entityId: agencyId })
        this.productLine = data
        this.productLineCacheData = this.productLine.map((item) => ({ ...item }))
      } catch (err) {
        this.$httpNotify(err)
      }
    },

    // 新增服务类型-show
    showServiceTypeModal() {
      this.addServiceTypeVisible = true
    },
    // 新增服务类型-cancel
    handleServiceTypeCancel() {
      this.addServiceTypeVisible = false
      this.$refs.serviceTypeForm.resetFields()
    },
    // 新增服务类型-submit
    async handleServiceTypeOk() {
      this.$refs.serviceTypeForm.validate(async (valid) => {
        if (valid) {
          this.loading = true
          try {
            const data = await this.$http('saveCusMaterial', {
              ...this.serviceTypeFormData,
              entityId: this.baseInfo.id,
              type: 1,
            })
            if (data) {
              setTimeout(() => {
                this.$refs.serviceTypeForm.resetFields()
                this.addServiceTypeVisible = false
                this.loading = false
                this.getCusServiceTypeList()
              }, 1000)
            }
          } catch (err) {
            this.loading = false
            this.$httpNotify(err)
          }
        }
      })
    },
    // 编辑服务类型表格数据-handleChange
    handleChangeServiceType(value, id, column) {
      const newData = [...this.serviceType]
      const target = newData.filter((item) => id === item.id)[0]
      if (target) {
        target[column] = value
        this.serviceType = newData
      }
    },
    // 编辑服务类型表格数据-handleEdit
    handleEditServiceType(id) {
      const newData = [...this.serviceType]
      const target = newData.filter((item) => id === item.id)[0]
      this.serviceTypeEditingKey = id
      if (target) {
        target.editable = true
        this.serviceType = newData
      }
    },
    // 编辑服务类型表格数据-handleSave
    async handleSaveServiceType(id, index) {
      const newData = [...this.serviceType]
      const newCacheData = [...this.serviceTypeCacheData]
      console.log(newCacheData, index, id)
      const target = newData.filter((item) => id === item.id)[0]
      const targetCache = newCacheData.filter((item) => id === item.id)[0]
      try {
        const data = await this.$http('updateCusMaterial', { ...target, entityId: this.baseInfo.id, type: 1 })
        if (data) {
          if (target && targetCache) {
            delete target.editable
            this.serviceType = newData
            Object.assign(targetCache, target)
            this.serviceTypeCacheData = newCacheData
          }
          this.serviceTypeEditingKey = ''
        }
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 编辑服务类型表格数据-handleCancel
    handleCancelServiceType(id) {
      const newData = [...this.serviceType]
      const target = newData.filter((item) => id === item.id)[0]
      this.serviceTypeEditingKey = ''
      if (target) {
        Object.assign(target, this.serviceTypeCacheData.filter((item) => id === item.id)[0])
        delete target.editable
        this.serviceType = newData
      }
    },
    // 编辑服务类型表格数据-handleDelete
    async handleDeleteServiceType(id) {
      try {
        const data = await this.$http('deleteCusMaterial', [id])
        if (data) {
          const dataSource = [...this.serviceType]
          this.serviceType = dataSource.filter((item) => item.id !== id)
        }
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 客户服务类型表-列表
    async getCusServiceTypeList() {
      const agencyId = this.$store.state.app.userInfo.agencyId
      try {
        const data = await this.$http('cusMaterialList', { entityId: agencyId, type: 1 })
        this.serviceType = data
        this.serviceTypeCacheData = this.serviceType.map((item) => ({ ...item }))
      } catch (err) {
        this.$httpNotify(err)
      }
    },

    // 新增文档类型-show
    showDocumentTypeModal() {
      this.addDocumentTypeVisible = true
    },
    // 新增文档类型-cancel
    handleDocumentTypeCancel() {
      this.addDocumentTypeVisible = false
      this.$refs.documentTypeForm.resetFields()
    },
    // 新增服务类型-submit
    handleDocumentTypeOk() {
      this.$refs.documentTypeForm.validate(async (valid) => {
        if (valid) {
          this.loading = true
          try {
            const data = await this.$http('saveCusMaterial', {
              ...this.documentTypeFormData,
              entityId: this.baseInfo.id,
              type: 2,
            })
            if (data) {
              setTimeout(() => {
                this.$refs.documentTypeForm.resetFields()
                this.addDocumentTypeVisible = false
                this.loading = false
                this.getCusDocumentTypeList()
              }, 1000)
            }
          } catch (err) {
            this.loading = false
            this.$httpNotify(err)
          }
        }
      })
    },
    // 编辑文档类型表格数据-handleChange
    handleChangeDocumentType(value, id, column) {
      const newData = [...this.documentType]
      const target = newData.filter((item) => id === item.id)[0]
      if (target) {
        target[column] = value
        this.documentType = newData
      }
    },
    // 编辑文档类型表格数据-handleEdit
    handleEditDocumentType(id) {
      const newData = [...this.documentType]
      const target = newData.filter((item) => id === item.id)[0]
      this.documentTypeEditingKey = id
      if (target) {
        target.editable = true
        this.documentType = newData
      }
    },
    // 编辑文档类型表格数据-handleSave
    async handleSaveDocumentType(id, index) {
      const newData = [...this.documentType]
      const newCacheData = [...this.documentTypeCacheData]
      console.log(newCacheData, index, id)
      const target = newData.filter((item) => id === item.id)[0]
      const targetCache = newCacheData.filter((item) => id === item.id)[0]
      try {
        const data = await this.$http('updateCusMaterial', { ...target, entityId: this.baseInfo.id, type: 2 })
        if (data) {
          if (target && targetCache) {
            delete target.editable
            this.documentType = newData
            Object.assign(targetCache, target)
            this.documentTypeCacheData = newCacheData
          }
          this.documentTypeEditingKey = ''
        }
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 编辑文档类型表格数据-handleCancel
    handleCancelDocumentType(id) {
      const newData = [...this.documentType]
      const target = newData.filter((item) => id === item.id)[0]
      this.documentTypeEditingKey = ''
      if (target) {
        Object.assign(target, this.documentTypeCacheData.filter((item) => id === item.id)[0])
        delete target.editable
        this.documentType = newData
      }
    },
    // 编辑文档类型表格数据-handleDelete
    async handleDeleteDocumentType(id) {
      try {
        const data = await this.$http('deleteCusMaterial', [id])
        if (data) {
          const dataSource = [...this.documentType]
          this.documentType = dataSource.filter((item) => item.id !== id)
        }
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 客户文档类型表-列表
    async getCusDocumentTypeList() {
      const agencyId = this.$store.state.app.userInfo.agencyId
      try {
        const data = await this.$http('cusMaterialList', { entityId: agencyId, type: 2 })
        this.documentType = data
        this.documentTypeCacheData = this.documentType.map((item) => ({ ...item }))
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    getCurrency(val) {
      return this.$store.getters['app/getDictLabel']('CURRENCY', val)?.split(';')[0]
    },
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
.field-service,
.field-document {
  .editable-row-operations a {
    margin-right: 15px;
  }
  .title-btn-container {
    display: flex;
    justify-content: space-between;
    margin: 20px 0 15px;
    font-weight: 700;
  }
  /deep/.ant-table-tbody {
    text-align: center;
    & tr:nth-child(1) {
      & td:nth-child(1) {
        color: @text_color_2;
        background-color: rgba(222, 232, 243, 0.25);
      }
    }
  }
}
.ant-descriptions-view {
  .ant-select {
    width: 100%;
  }
}
</style>
