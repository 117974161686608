<template>
  <div>
    <a-tooltip v-show="showToolTip" placement="topLeft">
      <template slot="title">
        <div>
          {{ content }}
        </div>
      </template>
      <div ref="contentRef" :class="({ content: tip }, { className })">
        {{ content }}
      </div>
    </a-tooltip>
    <div v-show="!showToolTip" ref="contentRef" :class="({ content: tip }, { className })">
      {{ content }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'item',
  props: {
    content: {
      type: String,
      default: '',
    },
    tip: {
      type: Boolean,
      default: true,
    },
    className: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showToolTip: false,
    }
  },
  mounted() {
    this.tip && this.isShowToolTip()
  },
  methods: {
    isShowToolTip() {
      const el = this.$refs.contentRef
      this.showToolTip = el.clientWidth < el.scrollWidth
    },
  },
}
</script>

<style lang="less" scoped>
.content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
