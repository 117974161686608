<template>
  <div class="supplier-manage" v-show="show">
    <header>
      <a-button class="add-provide-btn" type="primary" @click="$router.push({ path: '/coConfig/addNewSupplier' })">{{
        $t('customerConfig.add_new_supplier')
      }}</a-button>
    </header>
    <main>
      <!-- 表格展示 -->
      <a-table
        class="head-bg no-br custom-table-pad"
        bordered
        size="middle"
        rowKey="id"
        :rowClassName="getRowClass"
        :data-source="dataInfo"
        :pagination="false"
        :loading="tableLoading"
        :scroll="{ x: $g.scrollXWidth }"
      >
        <a-table-column key="taxIdNumber" :ellipsis="true" :title="$t('customerConfig.supplier_tax')">
          <template slot-scope="row">
            <Item :content="row.taxIdNumber" />
          </template>
        </a-table-column>
        <a-table-column key="name" :ellipsis="false" :title="$t('customerConfig.supplier_name')">
          <template slot-scope="row">
            <Item :content="row.name" :tip="false" />
          </template>
        </a-table-column>
        <a-table-column key="address" :ellipsis="false" :title="$t('customerConfig.supplier_address')">
          <template slot-scope="row">
            <Item :content="row.address" :tip="false" />
          </template>
        </a-table-column>
        <a-table-column key="status" :ellipsis="true" :title="$t('orderInfo.table_order_status')">
          <template slot-scope="row">
            <span v-if="row.inactive" class="c-3">{{ $t('customerConfig.disabled') }}</span>
            <span v-else :class="row.status | statusClass">{{ row.status | statusText }}</span>
          </template>
        </a-table-column>
        <a-table-column :ellipsis="true" :title="$t('action')" :width="250" class="tbl-operation">
          <div slot-scope="row" class="_fle">
            <span class="tbl-operation-btn pointer primary mr-4" @click="viewDetails(row)">{{
              $t('customer.check_details')
            }}</span>
            <span
              :class="row.inactive === 1 || row.status === 1 ? '_hide2' : ''"
              class="tbl-operation-btn pointer primary mr-4"
              @click="handleSendEmail(row)"
              >{{ $t('customerConfig.send_ok_email') }}</span
            >
            <!-- <a-popconfirm
              v-show="row.inactive === 0"
              class="_ml-10"
              :title="$t('customerConfig.confirm_disabled')"
              @confirm="handleDisable(row)"
            > -->
            <span
              v-show="row.inactive === 0"
              @click="handleDisable(row)"
              class="tbl-operation-btn pointer primary mr-4 _ml-10"
              >{{ $t('customerConfig.disable') }}</span
            >
            <!-- </a-popconfirm> -->
            <!-- <a-popconfirm
              v-show="row.inactive === 1"
              class="_ml-10"
              :title="$t('customerConfig.confirm_enable')"
              @confirm="handleEnable(row)"
            >
              <span class="tbl-operation-btn pointer primary mr-4">{{ $t('customerConfig.enable') }}</span>
            </a-popconfirm> -->
            <span
              v-show="row.inactive === 1"
              class="tbl-operation-btn pointer primary mr-4 _ml-10"
              :title="$t('customerConfig.confirm_enable')"
              @click="handleEnable(row)"
              >{{ $t('customerConfig.enable') }}</span
            >
          </div>
        </a-table-column>
      </a-table>
    </main>
  </div>
</template>

<script>
import Item from './components/item.vue'
import i18n from '@/i18n'
import { Icon } from 'ant-design-vue'
export default {
  name: 'supplierManage',
  components: {
    Item,
  },
  filters: {
    // 状态文字转换
    statusText: (num) => [i18n.t('customerConfig.status_0'), i18n.t('customerConfig.status_1')][num],
    // 状态class转换
    statusClass: (num) => ['error', 'success'][num],
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    cusEntityId: {
      type: String,
      default: '',
    },
  },
  watch: {
    show(v) {
      if (v) {
        this.fetch()
      }
    },
  },
  data() {
    return {
      tableLoading: false,
      dataInfo: [],
      pagination: {
        current: 1,
        size: 'middle',
        pageSize: 10,
        pageSizeOptions: ['10', '20', '50'],
        showSizeChanger: true,
        total: 0,
        onShowSizeChange: (current, pageSize) => {
          this.pagination.current = 1
          this.pagination.pageSize = pageSize
          this.fetch()
        },
        onChange: (page) => {
          this.pagination.current = page
          this.fetch()
        },
      },
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    getRowClass(record) {
      if (record.inactive === 1) {
        return 'c-3'
      }
    },
    async handleSendEmail({ id, supTenantCode }) {
      try {
        const params = { id, supTenantCode }
        // eslint-disable-next-line no-unused-vars
        const data = await this.$http('sendConfirmEmail', params)
        console.log(`data`, data)
        if (data) {
          this.$message.success(this.$t('customerConfig.send_email_succ'))
        } else {
          this.$message.error(this.$t('customerConfig.send_email_fail'))
        }
      } catch (err) {
        this.$httpNotify(err)
      }
    },

    async updateInactiveCheck({ id: supEntityId, supTenantCode }) {
      try {
        const params = { supEntityId, supTenantCode }
        // eslint-disable-next-line no-unused-vars
        const data = await this.$http('updateInactiveCheck', params)
        console.log(`data`, data)

        return data
      } catch (err) {
        this.$httpNotify(err)
        return 0
      }
    },

    async handleDisable(row) {
      console.log(`row`, row)
      const check = await this.updateInactiveCheck(row)
      // eslint-disable-next-line no-unused-vars
      let title = ''
      if (check) {
        // 可以禁用
        title = this.$t('customerConfig.confirm_disabled')
      } else {
        title = this.$t('customerConfig.has_order_confirm_diable')
      }
      if (check === 0) {
        return
      }
      this.$confirm({
        title,
        icon: (h) => h(Icon, { props: { type: 'exclamation-circle', theme: 'filled' } }),
        okText: this.$t('confirm'),
        cancelText: this.$t('cancel'),
        onOk: async () => {
          try {
            const { id } = row
            const params = {
              id,
              inactive: 1,
            }
            // eslint-disable-next-line no-unused-vars
            const data = await this.$http('updateInactive', params)
            console.log(`data`, data)
            this.$message.success(this.$t('prompt.set_success'))
            this.fetch()
          } catch (err) {
            this.$httpNotify(err)
          }
        },
      })
    },
    async handleEnable(row) {
      const { id } = row
      console.log(`row`, row)
      const params = {
        id,
        inactive: 0,
      }
      try {
        // eslint-disable-next-line no-unused-vars
        const data = await this.$http('updateInactive', params)
        console.log(`data`, data)
        this.$message.success(this.$t('prompt.set_success'))
        this.fetch()
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    async fetch() {
      const go = async () => {
        this.tableLoading = true
        try {
          if (!this.cusEntityId) {
            return console.error('cusEntityId为空！')
          }

          const data = await this.$http('supEntityList', { cusEntityId: this.cusEntityId })
          this.tableLoading = false
          console.log(`data`, data)
          this.dataInfo = data
        } catch (err) {
          this.tableLoading = false
          this.$httpNotify(err)
        }
      }

      setTimeout(() => {
        go()
      }, 100)
    },
    viewDetails(row) {
      const { id } = row
      console.log(`row`, row)
      this.$router.push({ path: '/coConfig/supplierDetail', query: { id } })
    },
  },
}
</script>

<style lang="less" scoped>
.supplier-manage {
  .add-provide-btn {
    margin: 20px auto;
  }

  .tbl-operation-btn {
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
  }

  //设置padding
  ::v-deep {
    .custom-table-pad {
      .ant-table-body {
        tr > th:first-child {
          padding-left: 30px !important;
        }

        .ant-table-tbody {
          & > tr > td:first-child {
            padding-left: 30px !important;
          }
        }
      }
    }
  }
}
</style>
